<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Purchase Order : {{PurchaseOrderCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor PO</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <div>
                                <CRow>
                                    <CInput id="PurchaseOrderCode" v-model="PurchaseOrderCode" class="col-sm-7 font-weight-bold" readonly />
                                    <CInput id="PurchaseOrderCodeSeq" v-model="PurchaseOrderCodeSeq" class="col-sm-4 font-weight-bold" type="number" min="0" :disabled="DisabledInput" :hidden="HideInput" />
                                </CRow>
                            </div>
                            <label id="errorPurchaseOrderCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="PurchaseOrderDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                                :disabled="DisabledInput"
                            ></date-picker>
                            <label id="errorPurchaseOrderDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor SP</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="SPNumber" v-model="SPNumber" class="font-weight-bold" :disabled="DisabledInput"/>
                            <label id="errorSPNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tipe</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Type" v-model="Type" class="font-weight-bold" readonly/>
                            <label id="errorType" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Cara Bayar</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PaymentType" v-model="PaymentType" class="font-weight-bold" readonly/>
                            <label id="errorPaymentType" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Jatuh Tempo</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput type="number" id="DueDate" v-model="DueDate" class="font-weight-bold" readonly/>
                            <label id="errorDueDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note" :disabled="DisabledInput"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note Print</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="NotePrint" :disabled="DisabledInput"></textarea>
                            <label id="errorNotePrint" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CRow>
                        <div class="col-sm-half">
                            <div class="row px-3">
                                <div style="width: 31%;">
                                    <label class="form-label font-weight-bold">Customer</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </div>
                                <div style="width: 8%;">
                                </div>
                                <div style="width: 61%;">
                                    <CInput id="Customer" v-model="Customer" class="font-weight-bold" readonly/>
                                    <label id="errorCustomer" class="form-error pb-2" style="display: none; color: red;"></label>
                                </div>  
                            </div>
                            <div class="row px-3">
                                <div style="width: 31%;">
                                    <label class="form-label font-weight-bold">Project</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </div>
                                <div style="width: 8%;">
                                </div>
                                <div style="width: 61%;">
                                    <CInput id="Project" v-model="Project" class="font-weight-bold" readonly/>
                                    <label id="errorProject" class="form-error pb-2" style="display: none; color: red;"></label>
                                </div>  
                            </div>
                            <div class="row px-3">
                                <div style="width: 31%;">
                                    <label class="form-label font-weight-bold">Alamat</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </div>
                                <div style="width: 8%;">
                                </div>
                                <div style="width: 61%;">
                                    <!-- <CSelect id="Address" class="font-weight-bold" :options="AddressData" :value.sync="Address" @change="onChangeAddress()" /> -->
                                    <v-select id="Address" class="pb-3" :options="AddressData" v-model="Address" @input="onChangeAddress()" :disabled="DisabledInput"/>
                                </div>  
                            </div>
                            <div class="row px-3">
                                <div style="width: 31%;">
                                </div>
                                <div style="width: 8%;">
                                </div>
                                <div style="width: 61%;">
                                    <textarea id="AddressInput" class="form-control" rows="3" v-model="AddressInput" :disabled="DisabledInput"></textarea>
                                    <label id="errorAddressInput" class="form-error pb-2" style="display: none; color: red;"></label>
                                </div>  
                            </div>
                            <div class="row px-3 pt-2">
                                <div style="width: 31%;">
                                </div>
                                <div style="width: 8%;">
                                </div>
                                <div style="width: 50%;">
                                    <div>
                                        <input type="checkbox" v-model="SaveAddress" :disabled="DisabledInput"/> <label class="font-weight-bold">Simpan Alamat</label>
                                    </div>
                                    <label id="errorSaveAddress" class="form-error pb-2" style="display: none; color: red;"></label>
                                </div>
                            </div>
                            <div class="row px-3">
                                <div style="width: 31%;">
                                </div>
                                <div style="width: 8%;">
                                </div>
                                <div style="width: 50%;">
                                    <div>
                                        <input type="checkbox" v-model="Sample" :disabled="DisabledInput"/> <label class="font-weight-bold">Sample</label>
                                    </div>
                                    <label id="errorSample" class="form-error pb-2" style="display: none; color: red;"></label>
                                </div>  
                            </div>
                        </div>
                        <div class="col-sm-half px-3">
                            <div class="p-3 border rounded">
                                <label class="form-label font-weight-bold">Informasi Customer</label>
                                <hr>
                                <CRow>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Deposit</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{Deposit}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Limit PO</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{LimitPO}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">PO (Non-Cash)</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{PONonCash}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Payment (Non-Cash)</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{Payment}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Outstanding (Non-Cash)</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{Outstanding}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Sisa Limit</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{RemainingLimit}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">BG Expire Date</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{BankWarrantyDate}}</label>
                                    </div>
                                </CRow>
                            </div>
                        </div>
                        <div class="col-sm-half px-3">
                            <div class="p-3 border rounded">
                                <label class="form-label font-weight-bold">Informasi {{Type}}</label>
                                <hr>
                                <CRow>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Forecast</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{ProjectForecastType}}</label>
                                    </div>
                                </CRow>
                                <CRow>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Salesman</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{ProjectSalesman}}</label>
                                    </div>
                                </CRow>
                                <CRow v-if="this.ProjectForecastType == 'None'">
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Limit</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{ProjectLimit}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">PO</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{ProjectPO}}</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Sisa Limit</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{ProjectRemainingLimit}}</label>
                                    </div>
                                </CRow>
                                <CRow v-if="this.ProjectForecastType == 'Forecast 1'">
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">PO ke</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <CSelect id="ProjectPOKe" class="font-weight-bold" :options="ProjectPOKeData" :value.sync="ProjectPOKe" @change="onChangePOKe()" disabled/>
                                    </div>
                                </CRow>
                                <CRow v-if="this.ProjectForecastType == 'Total'">
                                    <div class="col-sm-7">
                                        <label class="form-label font-weight-bold">Total</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <label class="form-label font-weight-bold" style="float:right">{{RetailTotalMinimumAmount}}</label>
                                    </div>
                                </CRow>
                            </div>
                        </div>
                    </CRow>
                    <CRow class="p-3" >
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>

                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :filterable="true"
                                        :sortable="true"
                                        :pageable="true"
                                        :resizable="true"
                            >
                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'price'"
                                                    :title="'Harga'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'price_discount'"
                                                    :title="'Diskon'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'net'"
                                                    :title="'Net'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'qty'"
                                                    :title="'Quantity'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'real_qty'"
                                                    :title="'Base Quantity'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'total_price'"
                                                    :title="'Total'"
                                                    :format="'{0:N0}'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'discount1'"
                                                    :title="'Diskon 1'"
                                                    :template="disc1Template"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'discount2'"
                                                    :title="'Diskon 2'"
                                                    :template="disc2Template"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'discount3'"
                                                    :title="'Diskon 3'"
                                                    :template="disc3Template"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'discount4'"
                                                    :title="'Diskon 4'"
                                                    :template="disc4Template"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'discount5'"
                                                    :title="'Diskon 5'"
                                                    :template="disc5Template"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'discount_cash'"
                                                    :title="'Diskon Cash'"
                                                    :template="disccashTemplate"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right' }"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    
                    <hr>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                             <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.FileStatus == 'Uploaded'">
                                            <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                        </div>
                                        <div v-if="this.FileStatus == 'New'">
                                            <label class="label-file">{{File}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload" ></i></label>
                                    <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Gross</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalGross" v-model="TotalGross" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>
                    <CRow class="pt-2">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pajak PPN (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="PPNTax" class="font-weight-bold" :options="PPNTaxData" :value.sync="PPNTax" @change="onChangePPNTax()" :disabled="DisabledInput"/>
                            <label id="errorPPNTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Disc (-)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalDisc" v-model="TotalDisc" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pajak PPN {{PPNTax}}%</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalPPNTax" v-model="TotalPPNTax" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>
                    <CRow class="py-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Grand Total</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="GrandTotal" v-model="GrandTotal" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Uang Muka</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="DownPayment" v-model="DownPayment" class="font-weight-bold form-control input-number" :disabled="DisabledInput"/>
                            <label id="errorDownPayment" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <br>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                            <label id="errorPOGeneral" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/purchase-order')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import FileViewer from '../../../../shared/FileViewer.vue';
import moment from 'moment';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import poServices from '../../../Transaction/PurchaseOrder/Script/PurchaseOrderScript.js';
import BookingOrderServices from '../Script/BookingOrderServices';


export default {
    name: 'PurchaseOrderFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'file-viewer': FileViewer,
    },
    async mounted () {
        this.$loading(true);

        if (this.FormType == 'Add'){
            this.DisabledInput = false;
            this.HideInput = false;

            var data = await poServices.getPurchaseOrderQuery(this.PoId);

            this.PurchaseOrderCode = data.purchase_order_number;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.PurchaseOrderDate = dateNow;

            this.SPNumber = 'Auto';
            this.Type = 'Booking';
            this.PaymentType = data.payment_method;
            if(this.PaymentType == "Cash") {
                this.DueDate = 0;
            }
            else {
                this.DueDate = data.term_of_payment;
            }

            this.Note = '';
            this.NotePrint = '';

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.Customer = data.customer_name;
            this.CustomerId = data.customer_id;
            this.Project = data.project_name;
            this.ProjectId = data.project_id;
            
            this.SaveAddress = false;
            this.Sample = data.sample;
            
            var customerData = data.customer.metadata;
            this.AddressData = customerData.shipping_address;
            this.Address = '';
            this.AddressInput = data.address;

            this.PPNTaxData = await poServices.getDropdownPPNTax();
            this.PPNTax = data.tax_percentage;

            var customer = await poServices.getContactDetailQuery({type : "Distributor", id : data.customer_id});
            //detail customer
            this.Deposit = 0;
            this.LimitPO = customerData.sale.po_limit;
            this.PONonCash = 0;
            this.Payment = 0;
            this.Outstanding = 0;
            this.RemainingLimit = customerData.sale.po_limit - parseFloat(customer.on_going_purchase_order_total_by_customer_id);
            var bankWarrantyDate = '';
            if(customerData.bg.bg_expire_date != ''){
                bankWarrantyDate = moment(customerData.bg.bg_expire_date).add(parseInt(customerData.bg.bg_extend == null ? 0 : customerData.bg.bg_extend), 'days').format("DD MMMM YYYY");
            }
            this.BankWarrantyDate = bankWarrantyDate;

            //detail project
            var projectData = data.project;
            var salesmanData = await poServices.getContactDetailQuery({type : "Employee", id : projectData.sales_id});
            this.ProjectLimit = projectData.project_detail.limit;
            this.ProjectPO = await poServices.getLimitRemaining(data.project_id, projectData.forecast_type);
            this.ProjectRemainingLimit = this.ProjectLimit - this.ProjectPO;
            this.ProjectSalesman = salesmanData.contact_name;
            this.ProjectForecastType = projectData.forecast_type;
            this.ProjectPOKeData = poServices.poDataDropDown(projectData.project_detail.total_po);
            this.ProjectPOKe = data.purchase_order_sequence;
            this.PriceCategory = projectData.price_category_id;
            this.Storage = projectData.storage_id;
            
            //File
            this.File = '';
            this.FileStatus = 'New';

            this.TotalGross = 0;
            this.TotalDisc = 0;
            this.TotalPPNTax = 0;
            this.GrandTotal = 0;
            this.DownPayment = 0;

            //Grid Item and Booking
            var bookingIds = this.BoId.split('|')
            var bookingData = await BookingOrderServices.getBookingPurchaseOrder(bookingIds, this.PoId);
            this.ItemGridData = BookingOrderServices.editItemGridDataGenerator(bookingData);
            this.countTotalPO();

            this.SaveType = 'Add';
        }
        else {
            this.DisabledInput = true;
            this.HideInput = true;

            var data = await poServices.getPurchaseOrderQuery(this.PoId);
            
            this.PurchaseOrderCode = data.purchase_order_number;
            this.PurchaseOrderDate = data.purchase_order_date;

            this.SPNumber = data.sp_number;
            this.Type = data.purchase_order_type;
            this.PaymentType = data.payment_method;
            this.DueDate = data.term_of_payment;

            this.Note = data.notes;
            this.NotePrint = data.printed_notes;

            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchase_order');
            this.Status = data.status;

            this.Customer = data.customer_name;
            this.CustomerId = data.customer_id;
            this.Project = data.project_name;
            this.ProjectId = data.project_id;
            
            this.SaveAddress = false;
            this.Sample = data.sample;
            
            var customerData = data.customer.metadata;
            this.AddressData = customerData.shipping_address;
            this.Address = '';
            this.AddressInput = data.address;

            this.PPNTaxData = await poServices.getDropdownPPNTax();
            this.PPNTax = data.tax_percentage;

            var customer = await poServices.getContactDetailQuery({type : "Distributor", id : data.customer_id});
            //detail customer
            this.Deposit = 0;
            this.LimitPO = customerData.sale.po_limit;
            this.PONonCash = 0;
            this.Payment = 0;
            this.Outstanding = 0;
            this.RemainingLimit = customerData.sale.po_limit - parseFloat(customer.on_going_purchase_order_total_by_customer_id);
            var bankWarrantyDate = '';
            if(customerData.bg.bg_expire_date != ''){
                bankWarrantyDate = moment(customerData.bg.bg_expire_date).add(parseInt(customerData.bg.bg_extend == null ? 0 : customerData.bg.bg_extend), 'days').format("DD MMMM YYYY");
            }
            this.BankWarrantyDate = bankWarrantyDate;

            //detail project
            var projectData = data.project;
            var salesmanData = await poServices.getContactDetailQuery({type : "Employee", id : projectData.sales_id});
            this.ProjectLimit = projectData.project_detail.limit;
            this.ProjectPO = await poServices.getLimitRemaining(data.project_id, projectData.forecast_type);
            this.ProjectRemainingLimit = this.ProjectLimit - this.ProjectPO;
            this.ProjectSalesman = salesmanData.contact_name;
            this.ProjectForecastType = projectData.forecast_type;
            this.ProjectPOKeData = poServices.poDataDropDown(projectData.project_detail.total_po);
            this.ProjectPOKe = data.purchase_order_sequence;
            this.PriceCategory = projectData.price_category_id;
            this.Storage = projectData.storage_id;
            
            //File
            this.File = data.attachment;
            this.FileStatus = 'Uploaded';

            //Grid Item and Booking
            this.ItemGridData = poServices.editItemGridDataGenerator(data.purchase_order_detail);
            this.countTotalPO();
            this.DownPayment = data.down_payment;
            
            this.SaveType = 'Edit';
            
            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }

        this.$loading(false);
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            PoId: this.$route.params.poId,
            BoId: this.$route.params.boId,
            Error : 0,

            //Detail
            PurchaseOrderCode: '',
            PurchaseOrderCodeSeq: 0,
            PurchaseOrderDate: '',
            SPNumber: '',
            Type: '',
            PaymentType: '',
            PaymentTypeData: [  {value: 'cash', label: 'Cash'},
                                {value: 'credit', label: 'Credit'},
                                {value: 'deposit', label: 'Deposit'}],
            PPNTaxData: [],
            PPNTax: 0,
            DueDate: 0,
            Note: '',
            NotePrint: '',
            Status: '',
            StatusData: [],
            Address: '',
            AddressData: [],
            AddressInput: [],
            Customer: '',
            CustomerId: '',
            Project: '',
            ProjectId: '',
            SaveAddress: false,
            Sample: false,
            
            Deposit: 0,
            LimitPO: 0,
            PONonCash: 0,
            Payment: 0,
            Outstanding: 0,
            RemainingLimit: 0,
            BankWarrantyDate: '',

            ProjectLimit: 0,
            ProjectPO: 0,
            ProjectRemainingLimit: 0,
            ProjectSalesman: '',
            ProjectForecastType: '',
            ProjectPOKeData : [{value: 0}],
            ProjectPOKe: 0,
            RetailTotalMinimumAmount: 0,
            Storage: '',
            PriceCategory: '',

            File: '',
            FileStatus: '',

            TotalGross: 0,
            TotalDisc: 0,
            TotalPPNTax: 0,
            GrandTotal: 0,
            DownPayment: 0,

            //grid
            ItemGridData: [],
            ItemSchemaModel: {
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                price: { type: "number", editable: false },
                price_discount: { type: "number", editable: false },
                net: { type: "number", editable: false },
                qty: { type: "number", editable: false },
                real_qty: { type: "number", editable: false },
                total_price: { type: "number", editable: false },
                discount1: { type: "string", editable: false },
                discount2: { type: "string", editable: false },
                discount3: { type: "string", editable: false },
                discount4: { type: "string", editable: false },
                discount5: { type: "string", editable: false },
                discount_cash: { type: "string", editable: false },
            },

            //disable variable
            DisabledInput: true,
            HideInput : true,
        }
    },
    methods: {
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        onChangeAddress(){
            if(this.Address != null){
                this.AddressInput = this.Address;
            }else{
                this.AddressInput = '';
            };
        },
        onChangePPNTax(){
            this.countTotalPO();
        },
        onChangePOKe(){
            this.ItemGridData = [];

            this.countTotalPO();
        },
        async resetTax(){
            if(this.$cookies.get('RoleName') == 'Distributor'){
                document.getElementById('PPNTax').disabled = true;
            }else{
                document.getElementById('PPNTax').disabled = false;
            }
            var ppnTax = await globalfunc.getPPNTax();
            this.PPNTax = ppnTax.label;
        },
        disc1Template: function(e) {
            return kendo.format('{0:N0}', e.discount_amount_1) + ' ('+ e.discount1 + '%)';
        },
        disc2Template: function(e) {
            return kendo.format('{0:N0}', e.discount_amount_2) + ' ('+ e.discount2 + '%)';
        },
        disc3Template: function(e) {
            return kendo.format('{0:N0}', e.discount_amount_3) + ' ('+ e.discount3 + '%)';
        },
        disc4Template: function(e) {
            return kendo.format('{0:N0}', e.discount_amount_4) + ' ('+ e.discount4 + '%)';
        },
        disc5Template: function(e) {
            return kendo.format('{0:N0}', e.discount_amount_5) + ' ('+ e.discount5 + '%)';
        },
        disccashTemplate: function(e) {
            return kendo.format('{0:N0}', e.discount_amount_cash) + ' ('+ e.discount_cash + '%)';
        },
        countTotalPO(){
            var array = this.ItemGridData;
            var totalGross = 0;
            var totalDisc = 0;
            var totalPPNTax = 0;
            var totalGrossDisc = 0;
            var grandTotal = 0;

            if(array.length > 0){
                array.forEach(e => {
                    totalGross = totalGross + (e.price * e.qty);
                    totalDisc = totalDisc + (e.price_discount * e.qty);
                    totalGrossDisc = totalGrossDisc + (e.net * e.qty);
                    totalPPNTax = totalPPNTax + (e.net * (this.PPNTax / 100)) * e.qty;
                });
            }

            grandTotal = totalGrossDisc + Math.round(totalPPNTax);

            this.TotalGross = parseFloat(kendo.toString(totalGross, "0.00"));
            this.TotalDisc =  parseFloat(kendo.toString(totalDisc, "0.00"));
            this.TotalPPNTax = parseFloat(kendo.toString(totalPPNTax, "0.00"));
            this.GrandTotal = parseFloat(kendo.toString(grandTotal ,"0.00"));
        },        
        async getDueDay(){
            var dueDate = 0;
            var customer = null;
            var project = null;
            var typeDetail = null;

            if (this.ProjectId == null || this.ProjectId == undefined || this.ProjectId == '') {
                project = null;
            } 
            else {
                project = this.ProjectId;
                customer = this.CustomerId;

                if (this.PaymentType == "Cash") {
                    dueDate = 0;
                } else {
                    typeDetail = poServices.mergeItemDueDayArray(this.ItemGridData, this.ItemBookingGridData);
                }
                
                dueDate = await poServices.getDueDate(customer, project, typeDetail);
            }
            
            this.DueDate = dueDate;
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.PurchaseOrderCode == '' || this.PurchaseOrderCode == null){
                this.errorShow('errorPurchaseOrderCode');
            }
            if(this.PurchaseOrderDate == '' || this.PurchaseOrderDate == null){
                this.errorShow('errorPurchaseOrderDate');
            }
            if(this.AddressInput == '' || this.AddressInput == null){
                this.errorShow('errorAddressInput');
            }
            var bankWarrantyDate = moment(this.BankWarrantyDate);
            var now = moment();
            if(bankWarrantyDate < now){
                this.$swal("Error", "Tanggal bank garansi sudah expired", "error");
                this.Error++;
            }
            if(this.ItemGridData.length < 1){
                this.$swal("Error", "Item tidak boleh kosong", "error");
                this.Error++;
            }
            else{
                if(this.ProjectForecastType == 'None'){
                    if(this.Status == 'New' || this.Status == 'Revisi'){
                        if((this.ProjectRemainingLimit - this.GrandTotal) < 0 && this.PaymentType != 'Cash'){
                            this.Error++;
                            this.$swal("Error", "Sisa limit project tidak mencukupi ", "error");
                        }
                    }
                }
                if(this.ProjectForecastType == 'Total'){
                    if(this.Status == 'New' || this.Status == 'Revisi'){
                        if((this.RetailTotalMinimumAmount - this.GrandTotal) > 0 ){
                            this.Error++;
                            this.$swal("Error", "Total harga item harus melebihi total retail", "error");
                        }
                    }
                }
                if(this.FormType == 'Add'){
                    if((this.RemainingLimit - this.GrandTotal) < 0 && this.PaymentType != 'Cash' ){
                        this.Error++;
                        this.$swal("Error", "Sisa limit PO customer tidak mencukupi ", "error");
                    }
                }
            }
        },
        async saveClick(){
            this.inputValidation();

            var file = document.getElementById('File').files[0];
            
            if(file == undefined)(
                file = null
            )

            //Proses
            if(this.Error == 0){
                // item GridData
                var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;

                var poDetail = poServices.transactionPurchaseOrderDetail(gridItem, [], this.PPNTax);
                
                //save Custom Address
                if(this.SaveAddress == true){
                    var duplicate = poServices.duplicateAddressChecker(this.AddressData, this.AddressInput)
                    if(duplicate == false){
                        var distributor = await poServices.getContactDetailQuery({type : "Distributor", id : this.CustomerId});
                        distributor.metadata.shipping_address.push(this.AddressInput);
                        delete distributor.contact_id;
                        delete distributor.on_going_purchase_order_total_by_customer_id;
                    
                        const variables = {
                            id : this.CustomerId.toString(),
                            data : distributor
                        }
                        
                        poServices.editDistributor(variables).then(res => {
                        }).catch(error => {
                            var errorMessage = globalfunc.errorExtractor(error)
                            this.$swal("Error", errorMessage, "error");
                        })
                    }
                }

                const poData = {
                    purchase_order_number: (this.PurchaseOrderCode + '-' + this.PurchaseOrderCodeSeq),
                    purchase_order_date: this.PurchaseOrderDate,
                    sp_number: this.SPNumber == '' ? null : this.SPNumber,
                    status: this.Status,
                    project_id: this.ProjectId,
                    customer_id: this.CustomerId,
                    storage_id: this.Storage,
                    price_category_id: this.PriceCategory,
                    purchase_order_sequence: parseInt(this.ProjectPOKe),
                    purchase_order_type: this.Type,
                    notes: this.Note,
                    printed_notes: this.NotePrint,
                    address: this.AddressInput,
                    attachment: file,
                    down_payment: this.DownPayment,
                    tax_percentage: this.PPNTax,
                    //total: this.GrandTotal,
                    sample: this.Sample,
                    term_of_payment: this.DueDate,
                    booking_purchase_order_id: this.PoId,
                    transaction_purchase_order_detail: poDetail
                }
                
                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : poData
                    }
                    
                    poServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/purchase-order' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.PoId,
                        data : poData
                    }

                    poServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/purchase-order'
                                }
                            });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#File').val('');
            this.FileStatus = '';
            this.File = '';
        }
    } 
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>